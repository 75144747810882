<template>
  <ResourceDialog
    ref="dialog"
    v-on="$listeners"
    :rules="rules"
    entity-name="Post de Lucru"
    collection-name="workstations"
  >
    <template slot-scope="{ data }">
      <!-- name field -->
      <ElFormItem label="Nume" prop="name">
        <ElInput v-model="data.name" />
      </ElFormItem>

      <!-- color field -->
      <ElFormItem label="Culoare" prop="color">
        <ElColorPicker
          v-model="data.color"
          color-format="hex"
          @active-change="value => (data.color = value)"
        />
      </ElFormItem>
      <div>
        <el-switch style="margin-left:180px;" active-text="Angajatul trece si text la sarcinile finale" v-model="data.withResultText" />
      </div>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialogMixin from "@/mixins/ResourceDialogMixin";

export default {
  mixins: [ResourceDialogMixin],
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: "Numele este obligatoriu",
            trigger: "blur"
          }
        ]
      };
    }
  }
};
</script>
