<template>
  <div>
    <Navigation />
    <div class="pa-5 flex space-between">
      <OperationFilters />
      <ElButton @click="$refs.operationDialog.add()" type="primary"
        >Adauga Operatiune</ElButton
      >
    </div>
    <!-- table -->
    <ResourceTable
      :query="query"
      :filter="filter"
      :default-sort="{ prop: 'data.name' }"
    >
      <ElTableColumn type="index" width="50" />
      <ElTableColumn prop="data.name" label="Nume" sortable />
      <ElTableColumn label="Post de Lucru">
        <ResourceLoader
          slot-scope="{ row }"
          v-if="row.data.workstationId"
          :path="`workstations/${row.data.workstationId}`"
        >
          <ElButton
            slot-scope="{ resource: workstation }"
            @click="$set(filters, 'workstation', workstation.id)"
            size="mini"
            >{{ workstation.data && workstation.data.name }}</ElButton
          >
        </ResourceLoader>
      </ElTableColumn>
      <ElTableColumn align="right">
        <template slot-scope="{ row }">
          <ElButton @click="$refs.operationDialog.edit(row.id)" size="mini"
            >Modifica</ElButton
          >
        </template>
      </ElTableColumn>
    </ResourceTable>
    <!-- dialogs -->
    <OperationDialog ref="operationDialog" />
  </div>
</template>

<script>
import FiltersMixin from "@/mixins/FiltersMixin";
import OperationDialog from "@/components/operations/OperationDialog";
import OperationFilters from "@/components/operations/OperationFilters";

export default {
  mixins: [FiltersMixin()],
  components: {
    OperationDialog,
    OperationFilters
  },
  computed: {
    query() {
      return this.$firestore().collection("operations");
    }
  },
  methods: {
    filter(row) {
      const { filters } = this;
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false;
        }
      }
      if (
        filters.workstation &&
        row.data.workstationId !== filters.workstation
      ) {
        return false;
      }
      return true;
    }
  }
};
</script>
