<template>
  <ResourceDialog
    ref="dialog"
    :allowDelete="$store.role === 0"
    v-on="$listeners"
    :rules="rules"
    :defaults="defaults"
    entity-name="Operatiune"
    collection-name="operations"
  >
    <template slot-scope="{ data }">
      <!-- name field -->
      <ElFormItem label="Nume" prop="name">
        <ElInput v-model="data.name" />
      </ElFormItem>
      <!-- workstation field -->
      <ElFormItem label="Post de Lucru" prop="workstationId">
        <ResourceSelect
          v-model="data.workstationId"
          collection-name="workstations"
          placeholder="Selecteaza"
          class="block"
        />
        <ElButton size="mini" @click="$refs.workstationDialog.add()"
          >Adauga Post de Lucru</ElButton
        >
      </ElFormItem>
      <!-- prices field -->
      <ElFormItem label="Preturi">
        <div class="prices-grid">
          <!-- quantities -->
          <input
            v-for="(bound, index) in data.quantityBounds"
            :key="`quantity_${index}`"
            v-model.number="data.quantityBounds[index]"
            type="text"
            :style="{ 'grid-row': 1, 'grid-column': index + 2 }"
            class="bg-gray-100"
          />
          <!-- parameters -->
          <input
            v-for="(bound, index) in data.parameterBounds"
            :key="`parameter_${index}`"
            v-model.number="data.parameterBounds[index]"
            type="text"
            :style="{ 'grid-row': index + 2, 'grid-column': 1 }"
            class="bg-gray-100"
          />
          <!-- prices -->
          <input
            v-for="(price, index) in data.prices"
            :key="`price_${index}`"
            v-model.number="data.prices[index]"
            type="text"
            :style="getCellPosition(index, data.quantityBounds.length)"
          />
        </div>
      </ElFormItem>
      <!-- quantities field -->
      <ElFormItem label="Cantitati">
        <ElButtonGroup>
          <ElButton @click="addColumn(data)" icon="el-icon-plus" size="mini" />
          <ElButton
            @click="removeColumn(data)"
            icon="el-icon-minus"
            size="mini"
          />
        </ElButtonGroup>
      </ElFormItem>
      <!-- parameters field -->
      <ElFormItem label="Parametri">
        <ElButtonGroup>
          <ElButton @click="addRow(data)" icon="el-icon-plus" size="mini" />
          <ElButton @click="removeRow(data)" icon="el-icon-minus" size="mini" />
        </ElButtonGroup>
      </ElFormItem>
      <!-- parameterName field -->
      <ElFormItem label="Nume Parametru" prop="parameterName">
        <ElInput v-model="data.parameterName" />
      </ElFormItem>
      <!-- nested dialogs -->
      <WorkstationDialog
        ref="workstationDialog"
        @insert="
          workstationRef => $set(data, 'workstationId', workstationRef.id)
        "
      />
    </template>
  </ResourceDialog>
</template>

<script>
import { chunk } from "lodash";
import ResourceDialogMixin from "@/mixins/ResourceDialogMixin";
import WorkstationDialog from "@/components/workstations/WorkstationDialog";

export default {
  mixins: [ResourceDialogMixin],
  components: {
    WorkstationDialog
  },
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: "Numele este obligatoriu",
            trigger: "blur"
          }
        ],
        workstationId: [
          {
            required: true,
            message: "Postul de lucru este obligatoriu",
            trigger: "change"
          }
        ]
      };
    },
    defaults() {
      return {
        quantityBounds: [0, 1000, 2000],
        parameterBounds: [1, 2],
        prices: [0, 0, 0, 0, 0, 0]
      };
    }
  },
  methods: {
    getCellPosition(index, columns) {
      return {
        "grid-row": Math.floor(index / columns) + 2,
        "grid-column": (index % columns) + 2
      };
    },
    addColumn(data) {
      const columns = data.quantityBounds.length;
      const lastQuantity = data.quantityBounds[columns - 1];
      data.quantityBounds = [...data.quantityBounds, lastQuantity + 1000];
      data.prices = chunk(data.prices, columns).flatMap(chunk => [...chunk, 0]);
    },
    removeColumn(data) {
      const columns = data.quantityBounds.length;
      if (columns === 1) return;
      data.quantityBounds = data.quantityBounds.slice(0, -1);
      data.prices = chunk(data.prices, columns).flatMap(chunk =>
        chunk.slice(0, -1)
      );
    },
    addRow(data) {
      const rows = data.parameterBounds.length;
      const columns = data.quantityBounds.length;
      const lastParameter = data.parameterBounds[rows - 1];
      data.parameterBounds = [...data.parameterBounds, lastParameter + 1];
      data.prices = [...data.prices, ...Array(columns).fill(0)];
    },
    removeRow(data) {
      const rows = data.parameterBounds.length;
      if (rows === 1) return;
      const columns = data.quantityBounds.length;
      data.parameterBounds = data.parameterBounds.slice(0, -1);
      data.prices = data.prices.slice(0, -1 * columns);
    }
  }
};
</script>

<style scoped>
.prices-grid {
  display: grid;
  grid-gap: 3px;
  grid-auto-columns: 65px;
}

.prices-grid > * {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 10px;
}
</style>
